import Vue from 'vue'
import Router from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
import store from "./vuex/store";
import PageNotFound from "@/pages/PageNotFound"

Vue.use(Router)

const RouterLayout = createRouterLayout(layout => {
  return import('@/layouts/' + layout + '.vue')
})

const router = new Router({
  mode: "history",
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes
    },
    { path: "*", component: PageNotFound }
  ]
})

router.onError((err)=>{
  if(err.code == 403) router.push({name: 'lostbutfound', params: {err}}).catch(()=>{})
})
export default router;
